<template>
  <div class="tab-pane show vivify fadeIn active"
       style="height:550px;"
       v-loading="loading">
    <div id="device-indoor-temp-char-detail"
         :style="{width:'100%',height:'100%','text-align':'center','line-height':'400px'}">{{$t("history.noData")}}</div>
  </div>
</template>
<script>
import {getMaxMinValue,getMaxMinSet} from '../../utils/tools.js'
export default {
  data () {
    return {
      isNoData: true,
      paramTitle: [],
      startdate: this.formatDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()) + " 00:00:00",
      enddate: this.formatDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()) + " 23:59:59",
      calendarType: "date",
      deviceTypeList: [],
      oldPsId: null,
      psId: null,
      oldKeyParams: [],
      oldcheckDeviceTypeIds: [],
      searchdate: null,
      oldsearchdate: null,
      devTypeId: null,
      deviceTypeIds: null,
      loading: false,
      myChart: null,
      charDatas: [],
      paraDatas: [],
      lxcheckDeviceTypeIds: [],
      keyDatas: [],
      keyParams: [],
      setRackFlag: false,//true查询电芯，false反之
      charDatasKey: [],
      maxValue:[],
      minValue:[],
      flagValue:false,
      units: [
        { "deviceTypeId": 1, "unit": { "pac": "kW", "rVac": "V", "sVac": "V", "tVac": "V", "itemp": "°C", "temp": "°C", "inTotal": "kW", "outTotal": "kW","dayPac":"kW" } },
        { "deviceTypeId": 2, "unit": { "bcp": "kW", "ppv": "kW", "vpv": "V", "pvTotal": "kW","itemp": "°C", "temp": "°C" } },
        { "deviceTypeId": 3, "unit": { "bcp": "kW", "bv": "V", "bvmax": "V", "bvmin": "V", "btMax": "°C", "btMin": "°C", "btmax": "°C", "btmin": "°C", "soc": "%", "mcpList": "kW", "mvMaxList": "V", "mvMinList": "V", "mtMaxList": "°C", "mtMinList": "°C", "msocList": "%" } },
        { "deviceTypeId": 4, "unit": { "temp": "°C", "indoortemp": "°C", "humidity": "RH", "maxindoortemp": "°C", "minindoortemp": "°C", "outdoortemp": "°C" } },
        { "deviceTypeId": 5, "unit": { "pac": "kW", "gridEngery": "kW", "sendEngery": "kW" } },
      ],
      yAxisNams: [{
        "deviceTypeId": 1,
        "yAxisName":
        {
          "kW": 'charts.pcs.inOutPower',//"功率",
          "V": 'charts.pcs.voltage',//"电压",
          "A": 'charts.pcs.current',//"电流",
          "°C": 'charts.pcs.temperature',//"温度"
        }
      }, {
        "deviceTypeId": 2,
        "yAxisName":
        {
          "kW": 'charts.dcdc.power',//"功率",
          "V": 'charts.dcdc.voltage',//"电压",
          "A": 'charts.dcdc.current',//"电流"，
          "°C": 'charts.dcdc.temperature',//"温度"
        }
      }, {
        "deviceTypeId": 3,
        "yAxisName":
        {
          "kW": 'charts.bms.power',//"功率",
          "V": 'charts.bms.voltage',//"电压",
          "A": 'charts.bms.current',//"电流",
          "%": 'charts.bms.soc',//"soc"
          "°C": 'charts.bms.temperature',//"温度"
        }
      }, {
        "deviceTypeId": -3,
        "yAxisName":
        {
          "kW": 'charts.rack.power',//"功率",
          "V": 'charts.rack.voltage',//"电压",
          "A": 'charts.rack.current',//"电流",
          "%": 'charts.rack.soc',//"soc"
          "°C": 'charts.rack.temperature',//"温度"
        }
      }, {
        "deviceTypeId": 4,
        "yAxisName":
        {
          "kW": 'charts.air.power',//"功率",
          "V": 'charts.air.voltage',//"电压",
          "A": 'charts.air.current',//"电流",
          "°C": 'charts.air.temperature',//"温度"
        }
      }, {
        "deviceTypeId": 5,
        "yAxisName":
        {
          "kW": 'charts.ammter.power',//"功率",
          "V": 'charts.ammter.voltage',//"电压",
          "A": 'charts.ammter.current',//"电流",
          "°C": 'charts.ammter.temperature',//"温度"
        }
      }]
    }
  },
  props: ['deviceTypePsList','calendarTypes', 'psIds', 'checkDeviceTypeIds', "checkDeviceTypeId", 'searchDate', 'searchBtn', 'params', 'chooseDatas', 'initSons', 'isDevsShows'],
  watch: {
    deviceTypePsList: {
      deep:true,
      handler (deviceTypePsList) {
        //this.deviceTypeList = [1, 2, 3, 4, 5]
        if(deviceTypePsList.length > 0){
          this.deviceTypeList = deviceTypePsList;
          //console.info(33333333,this.deviceTypeList)
        }
      }
    },
    calendarTypes: {
      handler (calendarTypes) {
        this.calendarType = calendarTypes;
        this.keyParams = [];
        this.initKeyData();
        if (this.myChart != null) {
          this.myChart.setOption({});
          //没有参数销毁图表对象
          this.myChart.dispose();
        }
      }
    },
    psIds: {
      handler (psIds, oldPsIds) {
        this.psId = psIds
        if (this.oldPsId == null) {
          this.oldPsId = this.psId;
        }
      }
    },
    checkDeviceTypeIds: {
      handler (checkDeviceTypeIds) {
        //console.log("组件 checkDeviceTypeIds", checkDeviceTypeIds)
        if (checkDeviceTypeIds != "loading") {
          checkDeviceTypeIds = this.unique(checkDeviceTypeIds)
          this.deviceTypeIds = checkDeviceTypeIds;
          console.log("组件 this.deviceTypeIds", this.deviceTypeIds)
        }
        //取消所有设备后将模型重置
        if (checkDeviceTypeIds.length == 0) {
          this.initModelData();
          this.setModelData();
        }
      }
    },
    checkDeviceTypeId: {
      handler (checkDeviceTypeId) {
        //console.log("组件 checkDeviceTypeIds", checkDeviceTypeIds)
        if (checkDeviceTypeId != null && checkDeviceTypeId.indexOf("_") >= 0 && checkDeviceTypeId.split("_")[0] != "") {
          this.devTypeId = checkDeviceTypeId.split("_")[0];
        }
        if (checkDeviceTypeId != "loading") {
          this.initKeyData();
          if (this.oldPsId != this.psId) {
            this.oldPsId = this.psId;
          } else if (this.oldsearchdate != this.searchdate) {
            this.oldsearchdate = this.searchdate
          }
          if (this.calendarType == "date") {
            this.getDeviceDetailsByPsIdTypeIds();
          } else if (this.calendarType == "month") {
            this.getDeviceDaysByPsIdTypeIds();
          }

        } else {
          if (this.myChart != null) {
            this.myChart.setOption({});
            //没有参数销毁图表对象
            this.myChart.dispose();
          }
        }
      }
    },
    searchDate: {
      handler (searchDate, oldsearchDate) {
        if (searchDate != null) {
          this.searchdate = searchDate;
          if (this.oldsearchdate == null) {
            this.oldsearchdate = this.searchdate;
          }
          if (this.searchdate.indexOf(" ") >= 0) {
            this.searchdate = this.searchdate.split(" ")[0];
          }
          if (this.searchdate.indexOf("-") >= 0) {
            if (this.searchdate.split("-").length == 2) {
              this.searchdate = this.searchdate + "-01";
            }
          }
          if (this.myChart != null) {
            this.myChart.setOption({});
            //没有参数销毁图表对象
            this.myChart.dispose();
          }
        }
      }
    },
    chooseDatas: {
      handler (chooseDatas) {
        //this.keyDatas = chooseDatas;
      }
    },
    params: {
      handler (params, oldparams) {
        this.paramTitle = params;
        this.oldKeyParams = oldparams;
        console.info("oldparams", JSON.stringify(oldparams))
        console.info("params", JSON.stringify(params))
        this.initKeyData();
        if (params.length > 0) {
          this.detailChar();
        } else {
          if (this.myChart != null) {
            this.myChart.setOption({});
            //没有参数销毁图表对象
            this.myChart.dispose();
          }
        }
      }
    },
    initSons: {
      handler (initSons) {
        this.initModelData();
        this.setModelData();
      }
    },
    searchBtn: {
      handler (searchBtn) {
        //this.detailChar();
      }
    },
    isDevsShows: {
      handler (isDevsShows) {
        this.loading = isDevsShows
      }
    },
    resizeName: {
      handler () {
        this.myChart.resize();
      }
    },
  },
  created () {
    this.loading = false;
    this.initKeyData();
    this.initModelData();
    this.setModelData();
  },
  methods: {
    //设置模型数据
    setModelData () {
      switch (this.calendarType) {
        case "date":
          this.charDatasKey = [
            {
              deviceTypeId: 1,
              "param1": [{
                deviceTypeId: 1,
                label: this.$t("charts.pcs.inOutPower"),
                value: "pac",//充放/电功率
                visible: false,//true显示启用,false不启用
                group: 0,//坐标分组
              }],
              "param2": [{
                deviceTypeId: 1,
                label: this.$t("charts.pcs.ACVoltageA"),
                value: "rVac",//交流电压A
                visible: false,
                group: 1,
              }, {
                deviceTypeId: 1,
                label: this.$t("charts.pcs.ACVoltageB"),
                value: "sVac",//交流电压B
                visible: false,
                group: 1,
              }, {
                deviceTypeId: 1,
                label: this.$t("charts.pcs.ACVoltageC"),
                value: "tVac",//交流电压C
                visible: false,
                group: 1,
              }],
              "param3": [{
                deviceTypeId: 1,
                label: this.$t("charts.pcs.moduleItemp"),
                value: "itemp",  //模组温度
                visible: false,
                group: 2,
              },{
                deviceTypeId: 1,
                label: this.$t("charts.pcs.temperature"),
                value: "temp",  //环境温度
                visible: false,
                group: 2,
              }]
            }, {
              deviceTypeId: 2,
              "param1": [{
                deviceTypeId: 1,
                label: this.$t("charts.dcdc.pvPower"),
                value: "bcp",// 光伏发电功率
                visible: false,
                group: 0,
              }, {
                deviceTypeId: 2,
                label: this.$t("charts.dcdc.inPower"),
                value: "ppv",// 输入功率
                visible: false,
                group: 0,
              }],
              "param2": [{
                deviceTypeId: 2,
                label: this.$t("charts.dcdc.inVoltage"),
                value: "vpv",// 输入电压
                visible: false,
                group: 1,
              }],
              "param3": [{
                deviceTypeId: 2,
                label: this.$t("charts.dcdc.moduleItemp"),
                value: "itemp",  //模组温度
                visible: false,
                group: 2,
              },{
                deviceTypeId: 2,
                label: this.$t("charts.dcdc.temperature"),
                value: "temp",  //环境温度
                visible: false,
                group: 2,
              }]
            }, {
              deviceTypeId: 3,
              "param1": [{
                deviceTypeId: 3,
                label: this.$t("charts.bms.inOutPower"),
                value: "bcp",//充放/电功率
                visible: false,
                group: 0,
                rack: [{
                  deviceTypeId: 3,
                  label: this.$t("charts.rack.inOutPower"),
                  value: "mcpList",//充/放电功率
                  visible: false,
                  group: 0,
                }]
              }],
              "param2": [{
                deviceTypeId: 3,
                label: this.$t("charts.bms.busVoltage"),
                value: "bv",// 母线电压
                visible: false,
                group: 1,
              }, {
                deviceTypeId: 3,
                label: this.$t("charts.bms.rackMaxVoltage"),
                value: "bvmax",// 电芯最高电压
                visible: false,
                group: 1,
                rack: [{
                  deviceTypeId: 3,
                  label: this.$t("charts.rack.rackMaxVoltage"),
                  value: "mvMaxList",//电芯最高电压
                  visible: false,
                  group: 1,
                }]
              }, {
                deviceTypeId: 3,
                label: this.$t("charts.bms.rackMinVoltage"),
                value: "bvmin",// 电芯最低电压
                visible: false,
                group: 1,
                rack: [{
                  deviceTypeId: 3,
                  label: this.$t("charts.rack.rackMinVoltage"),
                  value: "mvMinList",//电芯最低电压
                  visible: false,
                  group: 1,
                }]
              }],
              "param3": [{
                deviceTypeId: 3,
                label: this.$t("charts.bms.rackHighTemp"),
                value: "btMax",  //电芯最高温度
                visible: false,
                group: 2,
                rack: [{
                  deviceTypeId: 3,
                  label: this.$t("charts.rack.rackHighTemp"),
                  value: "mtMaxList",  //电芯最高温度
                  visible: false,
                  group: 2,
                }]
              }, {
                deviceTypeId: 3,
                label: this.$t("charts.bms.rackLowTemp"),
                value: "btMin",  //电芯最低温度
                visible: false,
                group: 2,
                rack: [{
                  deviceTypeId: 3,
                  label: this.$t("charts.rack.rackLowTemp"),
                  value: "mtMinList",//电芯最低温度
                  visible: false,
                  group: 2,
                }]
              }],
              "param4": [{
                deviceTypeId: 3,
                label: this.$t("charts.bms.soc"),
                value: "soc",  //SOC
                visible: false,
                group: 3,
                rack: [{
                  deviceTypeId: 3,
                  label: this.$t("charts.rack.soc"),
                  value: "msocList",  //SOC
                  visible: false,
                  group: 3,
                }]
              }],
            }, {
              deviceTypeId: 4,
              "param3": [{
                deviceTypeId: 4,
                label: this.$t("charts.air.indoorTemp"),
                value: "indoortemp",//室内温度
                visible: false,
                group: 2,
              }]
            }, {
              deviceTypeId: 5,
              "param1": [{
                deviceTypeId: 5,
                label: this.$t("history.ammter.gridOrSend"),
                value: "pac",//功率
                visible: false,
                group: 0,
              }],
            }
          ];

          // , {
          //       deviceTypeId: 4,
          //       label: this.$t("charts.air.temp"),
          //       value: "outdoortemp",  //室外温度
          //       visible: false,
          //       group: 2,
          //     }
          break
        case "month":
          let PcsSet = [{
              "deviceTypeId": 1,
              label: this.$t("charts.pcs.inTotal"),
              value: "inTotal",//充电量
              visible: false,
              group: 0,
            }, {
              "deviceTypeId": 1,
              label: this.$t("charts.pcs.outTotal"),
              value: "outTotal",//放电量
              visible: false,
              group: 0,
            }]
            //电站类型过积载2，value=totalpac
          if(this.$store.state.psTypeId == "2"){
            PcsSet = [{
              "deviceTypeId": 1,
               label: this.$t("history.pcs.char.electricity"),
               value: "dayPac",//发电量
               visible: false,
               group: 0,
            }]
          }
          this.charDatasKey = [{
            "deviceTypeId": 1,
            "param1": PcsSet,
          }, {
            "deviceTypeId": 2,
            "param1": [{
              "deviceTypeId": 2,
              label: this.$t("charts.dcdc.pvTotal"),
              value: "pvTotal",// 光伏发电量
              visible: false,
              group: 0,
            }],
          }, {
            "deviceTypeId": 3,
            "param2": [{
              "deviceTypeId": 3,
              label: this.$t("charts.bms.rackMaxVoltage"),
              value: "bvmax",// 电芯最高电压
              visible: false,
              group: 1,
              rack: [{
                "deviceTypeId": 3,
                label: this.$t("charts.rack.rackMaxVoltage"),
                value: "mvMaxList",//电芯最高电压
                visible: false,
                group: 1,
              }]
            }, {
              "deviceTypeId": 3,
              label: this.$t("charts.bms.rackMinVoltage"),
              value: "bvmin",// 电芯最低电压
              visible: false,
              group: 1,
              rack: [{
                "deviceTypeId": 3,
                label: this.$t("charts.rack.rackMinVoltage"),
                value: "mvMinList",//电芯最低电压
                visible: false,
                group: 1,
              }]
            }],
            "param3": [{
              "deviceTypeId": 3,
              label: this.$t("charts.bms.rackHighTemp"),
              value: "btmax",  //电芯最高温度
              visible: false,
              group: 2,
              rack: [{
                "deviceTypeId": 3,
                label: this.$t("charts.rack.rackHighTemp"),
                value: "mtMaxList",  //电芯最高温度
                visible: false,
                group: 2,
              }]
            }, {
              "deviceTypeId": 3,
              label: this.$t("charts.bms.rackLowTemp"),
              value: "btmin",  //电芯最低温度
              visible: false,
              group: 2,
              rack: [{
                "deviceTypeId": 3,
                label: this.$t("charts.rack.rackLowTemp"),
                value: "mtMinList",//电芯最低温度
                visible: false,
                group: 2,
              }]
            }],
          }, {
            "deviceTypeId": 4,
            "param3": [{
              "deviceTypeId": 4,
              label: this.$t("charts.air.maxindoortemp"),
              value: "maxindoortemp",//室内最高温度
              visible: false,
              group: 2,
            }, {
              "deviceTypeId": 4,
              label: this.$t("charts.air.minindoortemp"),
              value: "minindoortemp", //室内最低温度
              visible: false,
              group: 2,
            }]
          }, {
            "deviceTypeId": 5,
            "param1": [{
              "deviceTypeId": 5,
              label: this.$t("charts.ammter.gridEngery"),
              value: "gridEngery",// 买电量
              visible: false,
              group: 0,
            }, {
              "deviceTypeId": 5,
              label: this.$t("charts.ammter.Send"),
              value: "sendEngery",// 卖电量
              visible: false,
              group: 0,
            }],
          }]
          break;
      }
    },
    //设置参数
    initModelData () {
      this.keyParams = [];
      this.charDatas = [];
      this.deviceTypeIds = [];
      this.isNoData = true;
    },
    //设备查询月数据
    async getDeviceDaysByPsIdTypeIds () {
      var _this = this;
      //this.loading = true;
      let userId = window.sessionStorage.getItem("userId");

      console.log("当前所有选中的设备类型" + this.deviceTypeIds)
      console.log("当前要查询的设备类型：" + this.devTypeId)
      if (this.devTypeId != null && this.devTypeId != "") {

        let sqlFlag = true; //如果取消点击设备,设备数据列表中存在当前ID就不做查询
        let lxdeviceTypeIds = this.getArrDifference(this.deviceTypeList, this.deviceTypeIds);
        if (lxdeviceTypeIds.length > 0 && parseInt(this.devTypeId) > 0) {
          let index = -1;
          if (this.charDatas.length > 0) {
            for (let i = 0; i < this.charDatas.length; i++) {
              for (let j = 0; j < lxdeviceTypeIds.length; j++) {
                if (this.charDatas[i]["deviceTypeId"] == lxdeviceTypeIds[j] && lxdeviceTypeIds[j] == this.devTypeId) {
                  sqlFlag = false;
                }
              }
            }
          }
        }

        if (sqlFlag) {
          let deviceData = {};
          // this.loading = true;
          this.$emit("devShowLoading", true);
          const { data: res } = await this.$http.get('/service-device/pssys/getDeviceDaysByPsidTypeid?psId=' + this.$store.state.psId + '&deviceTypeId=' + this.devTypeId + '&searchDate=' + this.searchdate, {});
          if (res.status == 200) {
            for (let i = 0; i < res.data.length; i++) {
              let datas = res.data[i]["datas"];
              for (let j = 0; j < datas.length; j++) {
                this.isNoData = false;
                let getdatetime = datas[j]["getdatetime"];
                if (getdatetime != undefined && getdatetime != null && getdatetime.indexOf(" ") >= 0) {
                  let gettime = getdatetime.split(" ")[1];
                  let gettimeArr = gettime.split(":");
                  res.data[i]["datas"][j]["getdatetime"] = getdatetime.split(" ")[0] + " " + gettimeArr[0] + ":" + gettimeArr[1] + ":00";
                }
                //console.info("getdatetime", getdatetime)
              }
            }
            let dataFlag = false;
            for (let i = 0; i < this.charDatas.length; i++) {
              if (this.charDatas[i]["deviceTypeId"] == this.devTypeId) {
                this.charDatas[i]["data"] = res.data;
                dataFlag = true;
                break;
              }
            }
            if (!dataFlag) {
              let deviceData = { "deviceTypeId": this.devTypeId, "dataStatus": true, "data": res.data }
              this.charDatas.push(deviceData);
            }

          } else if (res.status == 564) {
            swal({
              //提示的标题
              title: _this.$t("interfaceMsg.600"),
              //提示的文本
              text: _this.$t("interfaceMsg.564"),
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("login.msg.determine") //'确定'
            });
          }
          this.$emit("devShowLoading", false);
        }

        if (this.params.length > 0) {
          this.detailChar();
        }

        if (this.isNoData) {
          var chartDom = document.getElementById('device-indoor-temp-char-detail');
          chartDom.style.cssText = "text-align:center;color: #999; border: none;line-height: 400px;width: 100%; height: 100%; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;";
          chartDom.innerHTML = _this.$t("history.noData");
          chartDom.removeAttribute('_echarts_instance_');
        }
      }
      console.info("charDaysDatas", this.charDatas)
    },
    //设备查询日数据
    async getDeviceDetailsByPsIdTypeIds () {
      var _this = this;
      //this.loading = true;
      //this.charDatas=[];
      let userId = window.sessionStorage.getItem("userId");
      console.log("当前所有选中的设备类型" + this.deviceTypeIds)
      console.log("当前要查询的设备类型：" + this.devTypeId)
      if (this.devTypeId != null && this.devTypeId != "") {

        let sqlFlag = true; //如果取消点击设备,设备数据列表中存在当前ID就不做查询
        let lxdeviceTypeIds = this.getArrDifference(this.deviceTypeList, this.deviceTypeIds);
        if (lxdeviceTypeIds.length > 0 && parseInt(this.devTypeId) > 0) {
          let index = -1;
          if (this.charDatas.length > 0) {
            for (let i = 0; i < this.charDatas.length; i++) {
              for (let j = 0; j < lxdeviceTypeIds.length; j++) {
                if (this.charDatas[i]["deviceTypeId"] == lxdeviceTypeIds[j] && lxdeviceTypeIds[j] == this.devTypeId) {
                  sqlFlag = false;
                }
              }
            }
          }
        }


        if (sqlFlag) {
          let deviceData = {};
          // this.loading = true;
          this.$emit("devShowLoading", true);
          const { data: res } = await this.$http.get('/service-device/pssys/getDeviceDetailsByPsidTypeid?psId=' + this.$store.state.psId + '&deviceTypeId=' + this.devTypeId + '&searchDate=' + this.searchdate, {});
          if (res.status == 200) {
            for (let i = 0; i < res.data.length; i++) {
              let datas = res.data[i]["datas"];
              for (let j = 0; j < datas.length; j++) {
                this.isNoData = false;
                let getdatetime = datas[j]["getdatetime"];
                if (getdatetime != undefined && getdatetime != null && getdatetime.indexOf(" ") >= 0) {
                  let gettime = getdatetime.split(" ")[1];
                  let gettimeArr = gettime.split(":");
                  res.data[i]["datas"][j]["getdatetime"] = getdatetime.split(" ")[0] + " " + gettimeArr[0] + ":" + gettimeArr[1] + ":00";
                }
              }
            }


            let dataFlag = false;
            for (let i = 0; i < this.charDatas.length; i++) {

              if (this.charDatas[i]["deviceTypeId"] == this.devTypeId) {
                this.charDatas[i]["data"] = res.data;
                dataFlag = true;
                break;
              }
            }
            if (!dataFlag) {
              let deviceData = { "deviceTypeId": this.devTypeId, "dataStatus": true, "data": res.data }
              this.charDatas.push(deviceData);
            }
          } else if (res.status == 564) {
            swal({
              //提示的标题
              title: _this.$t("interfaceMsg.600"),
              //提示的文本
              text: _this.$t("interfaceMsg.564"),
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("login.msg.determine") //'确定'
            });
          }
          this.$emit("devShowLoading", false);
        }

        if (this.params.length > 0) {
          this.detailChar();
        }

        if (this.isNoData) {
          var chartDom = document.getElementById('device-indoor-temp-char-detail');
          chartDom.style.cssText = "text-align:center;color: #999; border: none;line-height: 400px;width: 100%; height: 100%; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;";
          chartDom.innerHTML = _this.$t("history.noData");
          chartDom.removeAttribute('_echarts_instance_');
        }
        this.loading = false;

        console.info("charDetailsDatas", this.charDatas)
      }
    },
    unique (array) {
      let n = {}, r = [], len = array.length, val, type;
      for (let i = 0; i < array.length; i++) {
        val = array[i];
        type = typeof val;
        if (!n[val]) {
          n[val] = [type];
          r.push(val);
        } else if (n[val].indexOf(type) < 0) {
          n[val].push(type);
          r.push(val);
        }
      }
      return r;
    },
    getDeviceKeys () {
      console.info("topkeyParams", this.keyParams)
      let lxparam = [];

      this.lxcheckDeviceTypeIds = [];
      //除选中设备以外的模型visible值重置false不显示
      this.lxcheckDeviceTypeIds = this.getArrDifference(this.deviceTypeList, this.deviceTypeIds);
      console.info("lxcheckDeviceTypeIds", JSON.stringify(this.lxcheckDeviceTypeIds));

      //keyParams模型中已存在未选中的设备数据行排除
      for (let i = 0; i < this.lxcheckDeviceTypeIds.length; i++) {
        for (let j = 0; j < this.keyParams.length; j++) {
          if (this.keyParams[j]["deviceTypeId"] == this.lxcheckDeviceTypeIds[i]) {
            this.keyParams[j]["status"] = false
          }
        }
      }
      let lxsKeyParams = this.keyParams;
      this.keyParams = [];
      //刷入选中的设备数据行
      for (var i = 0; i < lxsKeyParams.length; i++) {
        if (lxsKeyParams[i]["status"] == undefined) {
          this.keyParams.push(lxsKeyParams[i]);
        }
      }


      console.info("oldkeyParams", this.keyParams)

      //将未选中的charDatasKey模型对象的visible重置成false
      for (let i = 0; i < this.charDatasKey.length; i++) {
        for (let j = 0; j < this.lxcheckDeviceTypeIds.length; j++) {
          if (this.charDatasKey[i]["deviceTypeId"] == this.lxcheckDeviceTypeIds[j]) {
            for (let item in this.charDatasKey[i]) {
              if (item.indexOf("param") >= 0) {
                let paramObj = this.charDatasKey[i][item];
                if (paramObj != undefined) {
                  paramObj[0]["visible"] = false;
                  if (this.charDatasKey[i]["deviceTypeId"] == "3") {
                    for (let f = 0; f < this.charDatasKey[i][item].length; f++) {
                      if (this.charDatasKey[i][item][f]["rack"] != undefined) {
                        paramObj = this.charDatasKey[i][item][f]["rack"];
                        paramObj[0]["visible"] = false;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }


      //选中蓄电池和其他设备（两项及以上）将蓄电池rack visible 重置成 false
      if (this.deviceTypeIds.indexOf(3) >= 0 && this.deviceTypeIds.length > 1) {
        for (let i = 0; i < this.charDatasKey.length; i++) {
          if (this.charDatasKey[i]["deviceTypeId"] == "3") {
            for (let item in this.charDatasKey[i]) {
              switch (item) {
                case "param1":
                case "param2":
                case "param3":
                case "param4":
                  let paramStr = this.charDatasKey[i][item]
                  for (let j = 0; j < paramStr.length; j++) {
                    paramStr[j]["visible"] = false;
                    //蓄电池rack设置
                    if (paramStr[j]["rack"] != undefined) {
                      for (let k = 0; k < paramStr[j]["rack"].length; k++) {
                        paramStr[j]["rack"][k]["visible"] = false;
                      }
                    }
                  }
                  break;
              }
            }
          }
        }
        //keyParams将蓄电池rack visible 重置成 false
        for (let i = this.keyParams.length - 1; i > 0; i--) {
          if (this.keyParams[i]["deviceTypeId"] == "3") {
            this.keyParams.splice(i, 1)
            //  for (let item in this.keyParams[i]) {
            //   switch (item) {
            //     case "param1":
            //     case "param2":
            //     case "param3":
            //     case "param4":
            //       let paramStr = this.keyParams[i][item]
            //       for (let j = 0; j < paramStr.length; j++) {
            //         //蓄电池rack设置
            //         if (paramStr[j]["rack"] != undefined) {
            //           for (let k = 0; k < paramStr[j]["rack"].length; k++) {
            //             paramStr[j]["rack"][k]["visible"] = false;
            //           }
            //         }
            //       }
            //       break;
            //   }
            // }
          }
        }
        console.info("删除蓄电池keyParams", JSON.stringify(this.keyParams))
      }

      console.info("charDatasKey", JSON.stringify(this.charDatasKey))


      //去除取消设备类型项,去除this.keyParams的取消的设备数组索引
      if (this.oldKeyParams.length > this.params.length) {
        lxparam = this.getArrDifference(this.oldKeyParams, this.params);
        console.info("lxparam", lxparam[0]);
        for (let i = 0; i < this.keyParams.length; i++) {
          if (Object.keys(this.keyParams[i])[1] == lxparam[0]) {
            this.keyParams[i][lxparam[0]][0]["visible"] = false;
          }
        }

        let lxkeyParams = this.keyParams;
        this.keyParams = [];
        for (let i = 0; i < lxkeyParams.length; i++) {
          //let name = Object.keys(lxkeyParams[i])[1]
          for (let item in lxkeyParams[i]) {
            switch (item) {
              case "param1":
              case "param2":
              case "param3":
              case "param4":
                if (lxkeyParams[i][item][0]["visible"]) {
                  this.keyParams.push(lxkeyParams[i]);
                }
                break;
            }
          }
        }

        // //点击设备二级参数取消选中的设备模型visible值设false
        // for (let i = 0; i < this.deviceTypeIds.length; i++) {
        //   for (let j = 0; j < this.charDatasKey.length; j++) {
        //     if (this.deviceTypeIds[i] == this.charDatasKey[j]["deviceTypeId"]) {
        //       for (let item in this.charDatasKey[j]) {
        //         if (item.indexOf("param") >= 0) {
        //           let paramObj = this.charDatasKey[j][item];
        //           if (paramObj != undefined) {
        //             paramObj[0]["visible"] = false;
        //             if (this.charDatasKey[j]["deviceTypeId"] == "3") {
        //               if (item != undefined) {
        //                 for (let f = 0; f < this.charDatasKey[j][item].length; f++) {
        //                   paramObj = this.charDatasKey[j][item][f]["rack"];
        //                   if (paramObj != undefined) {
        //                     paramObj[0]["visible"] = false;
        //                   }

        //                 }
        //               }
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
      }


      //根据设备选中项选中二级参数设置要显示的参数模型
      for (let f = 0; f < this.deviceTypeIds.length; f++) {
        for (let i = 0; i < this.charDatasKey.length; i++) {
          if (this.deviceTypeIds[f] == this.charDatasKey[i]["deviceTypeId"]) {
            for (let k = 0; k < this.params.length; k++) {
              for (let item in this.charDatasKey[i]) {
                if (item == this.params[k]) {
                  // let param = this.params[k];
                  let paramJson = {};
                  let paramObj = null;
                  paramObj = this.charDatasKey[i][item];
                  if (!paramObj[0]["visible"]) {
                    paramObj[0]["visible"] = true;
                    paramJson["paramsType"] = item;
                    paramJson["deviceTypeId"] = this.charDatasKey[i]["deviceTypeId"];
                    paramJson["keyvalue"] = paramObj[0]["value"];
                    paramJson[item] = paramObj;
                    this.keyParams.push(paramJson)
                  }
                  if (this.deviceTypeIds.length == 1 && this.deviceTypeIds[0] == 3) {
                    for (let j = 0; j < this.charDatasKey[i][item].length; j++) {
                      if (this.charDatasKey[i]["deviceTypeId"] == 3 && this.charDatasKey[i][item][j]["rack"] != undefined) {
                        paramJson = {};
                        paramObj = null;
                        paramObj = this.charDatasKey[i][item][j]["rack"];
                        if (!paramObj[0]["visible"]) {
                          paramObj[0]["visible"] = true;
                          paramJson["paramsType"] = item;
                          paramJson["deviceTypeId"] = this.charDatasKey[i]["deviceTypeId"];
                          paramJson["keyvalue"] = paramObj[0]["value"];
                          paramJson[item] = paramObj;
                          paramJson["deviceTypeName"] = "rack";
                          this.keyParams.push(paramJson);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }

      console.info("newcharDatasKey", JSON.stringify(this.charDatasKey))
      console.info("sortpara", JSON.stringify(this.keyParams))

      if (this.keyParams.length > 0) {
        //去除重复的查询内容
        this.keyParams = this.removeArr(this.keyParams)
      }

      console.info("sortpara", this.keyParams)
    },
    //生成图表
    detailChar () {
      var _this = this;
      if (this.charDatas != null && this.charDatas.length > 0) {

        console.info("topkeyParams", this.keyParams);
        this.setCharDatasKey();
        this.getDeviceKeys();

        let devCharDatas = [];
        let yAxis = [];
        let repeatUnit = [];
        let xAxis = [];
        let tooltip = {};


        //设置成图表的要显示字段数据格式
        for (let i = 0; i < this.keyParams.length; i++) {
          for (let j = 0; j < this.keyDatas.length; j++) {
            if (this.keyDatas[j]["visible"]) {
              for (let item in this.keyParams[i]) {
                if (item == this.keyDatas[j]["value"]) {
                  for (var k = 0; k < this.keyParams[i][this.keyDatas[j]["value"]].length; k++) {
                    let unitObj = {};
                    unitObj["deviceTypeId"] = this.keyParams[i]["deviceTypeId"];
                    for (var a = 0; a < this.units.length; a++) {
                      if (unitObj["deviceTypeId"] == this.units[a]["deviceTypeId"]) {
                        let names = this.keyDatas[j]["value"];
                        let paraname = this.keyParams[i][names][k]["value"];
                        unitObj["label"] = this.keyParams[i][names][k]["label"]
                        unitObj["value"] = paraname;
                        unitObj["unitName"] = this.units[a]["unit"][paraname];
                        unitObj["group"] = this.keyParams[i][names][k]["group"];
                        unitObj["paramsType"] = this.keyParams[i]["paramsType"];
                        repeatUnit.push(unitObj);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        //切换语言时改变参数label
        for (let i = 0; i < repeatUnit.length; i++) {
          for (let j = 0; j < this.charDatasKey.length; j++) {
            if (repeatUnit[i]["deviceTypeId"] == this.charDatasKey[j]["deviceTypeId"]) {
              for (let item in this.charDatasKey[j]) {
                if (item == repeatUnit[i]["paramsType"]) {
                  //除电芯以外的设备
                  for (let k = 0; k < this.charDatasKey[j][item].length; k++) {
                    if (repeatUnit[i]["value"] == this.charDatasKey[j][item][k]["value"]) {
                      repeatUnit[i]["label"] = this.charDatasKey[j][item][k]["label"];
                    }
                    if (this.charDatasKey[j][item][k]["rack"] != undefined) {
                      let rackparam = this.charDatasKey[j][item][k]["rack"];
                      for (let f = 0; f < rackparam.length; f++) {
                        if (repeatUnit[i]["value"] == rackparam[f]["value"]) {
                          repeatUnit[i]["label"] = rackparam[f]["label"];
                        }
                      }
                    }
                  }
                }

              }
            }
          }
        }

        console.info("repeatUnit", repeatUnit)
        let devNums = 0;
        for (let i = 0; i < repeatUnit.length; i++) {

          let yAixIndexs = {};
          let paramName = repeatUnit[i]["value"];
          let paramLable = this.$t(repeatUnit[i]["label"]);
          yAixIndexs[repeatUnit[i]["unitName"]] = i;
          let unit = repeatUnit[i]["unitName"];
          let yAxisName;
          for (let j = 0; j < this.yAxisNams.length; j++) {
            if (this.yAxisNams[j]["deviceTypeId"] == repeatUnit[i]["deviceTypeId"]) {
              yAxisName = this.yAxisNams[j]["yAxisName"];
            }
          }
          let yAxiName = this.$t(yAxisName[unit]);

          let yAxi = {
            type: 'value',
            // name: yAxiName + '(' + unit + ')',
            position: 'left',
            axisLabel: {
              formatter: '{value} ' + unit
            },
            splitLine: {
              show: false,
            }
          }

          yAxi["position"] = "";
          yAxi["offset"] = 0;
          yAxis.push([repeatUnit[i]["value"], repeatUnit[i]["group"], yAxi]);

          if (this.deviceTypeIds.length > 0) {
            for (let j = 0; j < this.charDatas.length; j++) {
              if (this.charDatas[j]["deviceTypeId"] == repeatUnit[i]["deviceTypeId"]) {
                let charDataObj = this.charDatas[j]["data"];//获取同一个设备类型下多个设备的data
                for (let k = 0; k < charDataObj.length; k++) {
                  let serieName = paramLable + '-' + charDataObj[k]["deviceName"];
                  let serieData = [];
                  let yIndex = [];
                  for (var a = 0; a < this.units.length; a++) {
                    if (this.units[a]['deviceTypeId'] == this.charDatas[j]["deviceTypeId"]) {
                      let name = this.units[a]["unit"][paramName]
                      yIndex = yAixIndexs[name]
                    }
                  }


                  for (let c = 0; c < charDataObj.length; c++) {
                    let datas = charDataObj[devNums]["datas"];
                    serieData = [];
                    for (let b = 0; b < datas.length; b++) {
                      if (this.calendarType == "month" && datas[b]["getdatetime"].indexOf(" ") >= 0) {
                        datas[b]["getdatetime"] = datas[b]["getdatetime"].split(" ")[0];
                      }
                      serieData.push([datas[b]["getdatetime"], datas[b][paramName]])
                      let nextTime;
                      if (b < datas.length - 1) {
                        nextTime = datas[b + 1]["getdatetime"]
                        //如果断数据时间超过30分钟以上，插入0数据
                        if (parseInt(this.getDateDiff(datas[b]["getdatetime"], nextTime, "minute")) >= 30) {
                          let getdatetime = datas[b]["getdatetime"];
                          getdatetime = this.getNowFormatDate(new Date(new Date(getdatetime).getTime() + 1000 * 60))
                          //console.info("时间",datas[b]["getdatetime"])
                          //console.info("差",this.getDateDiff(datas[b]["getdatetime"],nextTime,"minute"))
                          if (this.calendarType == "date") {
                            serieData.push([getdatetime, 0])
                          }
                        }
                      }
                    }

                    let serie = {
                      name: '-',
                      type: 'line',
                      smooth: false,
                      yAxisIndex: 0,
                      data: [],
                      emphasis: {
                        focus: 'series'
                      },
                    }
                    serie["keyvalue"] = repeatUnit[i]["value"];
                    serie["name"] = serieName;
                    if(this.deviceTypeIds.length > 0 && this.deviceTypeIds[0] == 3){
                      serie["deviceModule"] = charDataObj[k]["deviceModule"]
                    }
                    serie["yAxisIndex"] = yIndex;
                    serie["data"] = serieData;
                    serie["group"] = repeatUnit[i]["group"]
                    devCharDatas.push(serie);
                    if (devNums < charDataObj.length - 1 && charDataObj.length > 1) {
                      devNums++;
                    } else {
                      devNums = 0;
                    }
                    break;
                  }

                }
              }
            }
          }
        }
        console.info("devCharDatas", devCharDatas)
        //选中蓄电池电芯项数据处理
        if (this.deviceTypeIds.length > 0 && this.deviceTypeIds[0] == 3) {
          let arrParam;let deviceModule = 0;
          //设置device表 deviceModule实际路数
          deviceModule = parseInt(devCharDatas[0]["deviceModule"]) 
          for (let i = 0; i < devCharDatas.length; i++) {
            devCharDatas[i]["status"] = true;
            switch (devCharDatas[i]["keyvalue"]) {
              case "mcpList":
              case "mvMaxList":
              case "mvMinList":
              case "mtMaxList":
              case "mtMinList":
              case "msocList":
                let dxdata = [];
                let keyname = devCharDatas[i]["keyvalue"];
                let counts = 0;
                let lsdata = [];
                let yAxi = [];
                arrParam = [];

                if (devCharDatas[i]["data"].length > 0) {
                  dxdata = devCharDatas[i];
                  yAxi = yAxis[i];
                  devCharDatas[i]["status"] = false;
                  for (let k = 0; k < dxdata["data"].length; k++) {
                    let getdatetime = dxdata["data"][k][0];
                    if (dxdata["data"][k][1] != undefined && dxdata["data"][k][1].length > 0) {
                      //显示实际路数
                     //counts = dxdata["data"][k][1].length;
                     if(this.calendarType == "date"){
                      counts =  deviceModule
                     }else{
                      deviceModule = dxdata["data"][k][1].length
                      counts = deviceModule;
                     }
                      //dxdata["data"][k][1].length
                      for (var j = 0; j < deviceModule; j++) {
                        let objJosn = {};
                        objJosn["name"] = "RACK" + (j + 1);
                        objJosn["getdatetime"] = getdatetime;
                        if(j < dxdata["data"][k][1].length){
                          objJosn["value"] = dxdata["data"][k][1][j];
                        }else{
                           objJosn["value"] = "0"
                        }
                        lsdata.push(objJosn);
                      }
                    } else {
                      counts = 0;
                    }
                  }


                  if (counts > 0) {
                    for (let k = 0; k < counts; k++) {
                      let datas = []
                      for (let f = 0; f < lsdata.length; f++) {
                        let name = "RACK" + (k + 1);
                        if (name == lsdata[f]["name"]) {
                          datas.push([lsdata[f]["getdatetime"], lsdata[f]["value"]])
                          let nextTime;
                          if (f < lsdata.length - 1) {
                            nextTime = lsdata[f]["getdatetime"]
                            //如果断数据时间超过30分钟以上，插入0数据
                            if (parseInt(this.getDateDiff(lsdata[f]["getdatetime"], nextTime, "minute")) >= 30) {
                              let getdatetime = lsdata[f]["getdatetime"];
                              getdatetime = this.getNowFormatDate(new Date(new Date(getdatetime).getTime() + 1000 * 60))
                              //console.info("时间",datas[b]["getdatetime"])
                              //console.info("差",this.getDateDiff(datas[b]["getdatetime"],nextTime,"minute"))
                              if (this.calendarType == "date") {
                                serieData.push([getdatetime, 0])
                              }

                            }
                          }
                        }
                      }
                      let serie = {
                        name: '-',
                        type: 'line',
                        smooth: true,
                        yAxisIndex: 0,
                        data: [],
                        emphasis: {
                          focus: 'series'
                        },
                      }

                      serie["keyvalue"] = keyname + "" + (k + 1);
                      if (dxdata["name"].indexOf("-") >= 0) {
                        dxdata["name"] = dxdata["name"].split("-")[0];
                      }
                      serie["name"] = "RACK" + (k + 1) + " " + (dxdata["name"]);
                      serie["yAxisIndex"] = 0;
                      //serieData.push([getdatetime, dxdata["data"][k][1][j]])
                      serie["data"] = datas;
                      serie["group"] = dxdata["group"]
                      devCharDatas.push(serie);
                      yAxis.push(yAxi)
                    }
                  }
                }
                //console.info("lsdata",lsdata)
                break;
            }
          }

          let lsdevCharDatas = devCharDatas;
          devCharDatas = [];
          for (let i = 0; i < lsdevCharDatas.length; i++) {
            if (lsdevCharDatas[i]["status"]) {
              devCharDatas.push(lsdevCharDatas[i]);
            }
          }
        }

        if (devCharDatas.length > 0) {
          devCharDatas = this.sortByKey(devCharDatas, 'group')

          let group;
          let lsYAxis = yAxis;
          let position = "left";
          let offset = 0;
          group = devCharDatas[0]["group"];
          yAxis = [];
          let n = 0;
          let lastn = 0;
          for (let i = 0; i < devCharDatas.length; i++) {
            if (i == 0 || group != devCharDatas[i]["group"]) {
              group = devCharDatas[i]["group"]
              for (let j = 0; j < devCharDatas.length; j++) {
                if (group == devCharDatas[j]["group"]) {
                  devCharDatas[j]["yAxisIndex"] = n;
                }
                if (j < lsYAxis.length) {
                  if (group == lsYAxis[j][1] && devCharDatas[i]["keyvalue"] == lsYAxis[j][0]) {
                    lsYAxis[j][2]["position"] = position;
                    lsYAxis[j][2]["offset"] = offset;
                    lsYAxis[j][2]["yAxisIndex"] = n
                     yAxis.push(lsYAxis[j][2]);
                  }
                }


                if (j > 0 && j == devCharDatas.length - 1) {
                  if (position == "left") {
                    if (n == 0) {
                      position = "right";
                    }
                    if (n == 2) {
                      position = "right";
                      offset = -100;
                    }
                  } else {
                    if (n == 1) {
                      position = "left";
                      offset = -100;
                    }
                    if (n == 3) {
                      position = "left";
                      offset = -200;
                    }
                  }
                  n = n + 1;
                  lastn = n;
                }
              }
            }
          }
        }

        if (this.calendarType == "date") {
          this.startdate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(this.searchdate).getDate()) + " 00:00:00";
          if (new Date(this.startdate).toLocaleDateString() == new Date().toLocaleDateString())
            this.enddate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(this.searchdate).getDate()) + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":00";
          else
            this.enddate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(this.searchdate).getDate()) + " 23:59:59";
          xAxis = [
            {
              // min: this.startdate,
              // max: this.enddate,
              //type: 'category',
              splitNumber: 24,
              type: 'time',
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {
                formatter: function (time) {

                  let dt = new Date(time);
                  let hours = dt.getHours()
                  hours = hours < 10 ? '0' + hours : hours;
                  let minute = dt.getMinutes()
                  minute = minute < 10 ? '0' + minute : minute;
                  return hours + ":" + minute;
                }
              },
              splitLine: {
                show: false,
              }

            }
          ]

          tooltip = {
            trigger: 'axis',
            axisPointer: { type: 'cross' },
            formatter: function (params) {
              let time = params[0].axisValueLabel.split(":");
              var relVal = time[0] + ":" + time[1];
              for (var i = 0, l = params.length; i < l; i++) {
                relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value[1]
              }
              return relVal
            }
          }

          
          //设置最大最小值
          this.maxValue = [];
          this.minValue = [];
          let maxValue = 0;
          let minValue = 0;
          let maxArr = [];
          let minArr = [];
          let yAxisIndex = 0;  
          this.flagValue = false;
          let groupNum = 0;
          for(let i=0;i < devCharDatas.length;i++){
            if(yAxisIndex !=  devCharDatas[i]["yAxisIndex"]){
                 groupNum++;
                 yAxisIndex = devCharDatas[i]["yAxisIndex"]
                 maxValue = 0;
                 minValue = 0;
             }
             let dataValue = devCharDatas[i]["data"]
             if(dataValue != null){
               for(let j=0;j < dataValue.length;j++){
                   maxValue = parseFloat(dataValue[j][1]) >  maxValue ?  parseFloat(dataValue[j][1]) : maxValue;
                   minValue = parseFloat(dataValue[j][1]) <  minValue ?  parseFloat(dataValue[j][1]) : minValue;
               }
               let jsonMaxMin = getMaxMinValue(maxValue, minValue)
               maxArr[groupNum] = jsonMaxMin["max"]
               minArr[groupNum] = jsonMaxMin["min"]
               if(!this.flagValue){
                    this.flagValue =  jsonMaxMin["flag"]
               }
             }
          }
      
          console.info("maxArr",maxArr)
          console.info("minArr",minArr)
        
        
          for(let i=0;i < maxArr.length;i++){
              this.maxValue[i] = maxArr[i];
              this.minValue[i] = minArr[i];
          }
          //如果是true并且不是单坐标 代表有负数重设最大最小值
          if(this.flagValue && maxArr.length > 1){
              this.maxValue=[]
              this.minValue=[]
              for(let i=0;i < maxArr.length;i++){
                   let jsonMaxMin = getMaxMinSet(maxArr[i],minArr[i])
                   this.maxValue[i] = jsonMaxMin["max"];
                   this.minValue[i] = jsonMaxMin["min"];
              }
          }
          
           this.yAxis = this.removeYAxis(yAxis)  
            for(let i=0;i<yAxis.length;i++){
                yAxis[i]["min"] = this.minValue[i];
                yAxis[i]["max"] = this.maxValue[i];
            }
        } else if (this.calendarType == "month") {

          this.startdate = this.formatDate(new Date(this.searchdate).getFullYear(), (new Date(this.searchdate).getMonth() + 1), 1);
          this.enddate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, 0).getDate());
          let datesData = []
          let lastDate;
          //选中本月
          if (new Date().getFullYear() == new Date(this.searchdate).getFullYear() && (new Date().getMonth() + 1) == (new Date(this.searchdate).getMonth() + 1)){
            let dd = new Date().getDate() == 31 ? 0 : new Date().getDate();
            lastDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, dd).getDate();
          } 
          else
            lastDate = new Date(new Date(this.enddate).getFullYear(), new Date(this.enddate).getMonth() + 1, 0).getDate();

          for (let i = 0; i < lastDate; i++) {
            datesData.push(this.formatDate(new Date(this.startdate).getFullYear(), (new Date(this.startdate).getMonth() + 1), (i + 1)))
          }
          for (let i = 0; i < devCharDatas.length; i++) {
            switch (devCharDatas[i]["keyvalue"]) {
              case "inTotal":
              case "outTotal":
              case "pvTotal":
              case "gridEngery":
              case "sendEngery":
              case "dayPac":
                devCharDatas[i]["type"] = 'bar';
                devCharDatas[i]["stack"] = 'one';
                break
            }

          }

          xAxis = [
            {
              data: datesData,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {
                formatter: function (time) {

                  let dt = new Date(time);
                  let months = (dt.getMonth() + 1)
                  months = months < 10 ? '0' + months : months;
                  let days = dt.getDate();
                  days = days < 10 ? '0' + days : days;
                  return days;
                }
              },
              splitLine: {
                show: false,
              }

            }
          ]


          // xAxis = [
          //   {
          //     //data: datesData,
          //     min: this.startdate,
          //     max: this.enddate,
          //     splitNumber: lastDate,
          //     type: 'time',
          //     axisTick: {
          //       alignWithLabel: true
          //     },
          //     axisLabel: {
          //       formatter: function (time) {

          //         let dt = new Date(time);
          //         let months = (dt.getMonth() + 1)
          //         months = months < 10 ? '0' + months : months;
          //         let days = dt.getDate();
          //         days = days < 10 ? '0' + days : days;
          //         return months + "/" + days;
          //       }
          //     },
          //     splitLine: {
          //       show: false,
          //     }

          //   }
          // ]


          tooltip = {
            trigger: 'axis',
            axisPointer: { type: 'cross' },
            formatter: function (params) {
              let time = params[0].axisValueLabel.split(":");
              var relVal = time[0];
              for (var i = 0, l = params.length; i < l; i++) {
                relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value[1]
              }
              return relVal
            }
          }
        }

        console.info("yAxis", yAxis);
        console.info("devCharDatas", devCharDatas);


        //二级参数选中项有参数才生成图表
        this.loading = false;
        if (this.paramTitle.length > 0 && devCharDatas.length > 0) {

          var chartDom = document.getElementById('device-indoor-temp-char-detail');
          this.myChart =this.$echarts.init(chartDom, 'macarons');
          var option;

          option = {
            //图形上下右左边距设置
            grid: {
              left: '3%',
              right: '2.5%',
              bottom: 70,
              containLabel: true
            },
            tooltip: tooltip,
           // color: ['#007bff', '#5470c6', '#91cc75', '#fc8452', '#9a60b4', '#ea7ccc', '#91cc75'],
            legend: {

              orient: 'horizontal',
              bottom: 0,
              icon: 'stack',
            },
            xAxis: xAxis,
            yAxis: yAxis,
            series: devCharDatas,
          };
          option && this.myChart.setOption(option, true);

          this.myChart.on('finished', function () {
            _this.finishedLoad();
          });

          return true;
        } else {
          // var chartDom = document.getElementById('device-indoor-temp-char-detail');
          // chartDom.style.cssText = "text-align:center;color: #999; border: none;line-height: 400px;width: 100%; height: 100%; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;";
          // chartDom.innerHTML = _this.$t("history.noData");
          // chartDom.removeAttribute('_echarts_instance_');
        }
      } else {
        // var chartDom = document.getElementById('device-indoor-temp-char-detail');
        // chartDom.style.cssText = "text-align:center;color: #999; border: none;line-height: 400px;width: 100%; height: 100%; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;";
        // chartDom.innerHTML = _this.$t("history.noData");
        // chartDom.removeAttribute('_echarts_instance_');
      }
    },
    //设置语言lable
    setCharDatasKey () {
      let lxCharDatasKey = this.charDatasKey;
      this.setModelData();
      for (let i = 0; i < lxCharDatasKey.length; i++) {
        for (let j = 0; j < this.charDatasKey.length; j++) {
          if (this.charDatasKey[j]["deviceTypeId"] == lxCharDatasKey[i]["deviceTypeId"]) {
            for (let item in this.charDatasKey[j]) {
              switch (item) {
                case "param1":
                case "param2":
                case "param3":
                case "param4":
                  let devParams = this.charDatasKey[j][item];
                  for (let k = 0; k < devParams.length; k++) {
                    this.charDatasKey[j][item][k]["visible"] = lxCharDatasKey[i][item][k]["visible"];
                    if (this.charDatasKey[j][item][k]["rack"] != undefined) {
                      let rackpara = this.charDatasKey[j][item][k]["rack"];
                      let old_rackpara = lxCharDatasKey[i][item][k]["rack"];
                      for (let f = 0; f < rackpara.length; f++) {
                        rackpara[f]["visible"] = old_rackpara[f]["visible"];
                      }
                    }
                    break;
                  }
              }

            }
          }
        }
      }
      console.info("语言切换后charDatasKey", JSON.stringify(this.charDatasKey))
    },
    //设置要查询的二级菜单项
    initKeyData () {

      switch (this.calendarType) {
        case "date":
          this.keyDatas = [{
            value: "param1",
            visible: false,
          }, {
            value: "param2",
            visible: false,
          }, {
            value: "param3",
            visible: false,
          }, {
            value: "param4",
            visible: false,
          }]
          break;
        case "month":
          this.keyDatas = [{
            value: "param1",
            visible: false,
          }, {
            value: "param2",
            visible: false,
          }, {
            value: "param3",
            visible: false,
          }]
          break
      }
      if (this.params.length > 0) {
        for (let i = 0; i < this.keyDatas.length; i++) {
          this.keyDatas[i]["visible"] = false;
        }

        for (let i = 0; i < this.params.length; i++) {
          for (let j = 0; j < this.keyDatas.length; j++) {
            if (this.keyDatas[j]["value"] == this.params[i]) {
              this.keyDatas[j]["visible"] = true;
            }
          }
        }
      }

      console.info("initKeyData this.params", this.params)
      console.info("initKeyData keyDatas", this.keyDatas)
    },
    //图表加载完成后再点击
    finishedLoad () {
      this.$emit("dataLoading", false);
    },
    //去除重复项
    removeArr (arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length;) {
          if (arr[i].deviceTypeId == arr[j].deviceTypeId && arr[i].keyvalue == arr[j].keyvalue) {
            arr.splice(j, 1)
          } else {
            j++
          }
        }
      }
      return arr
    },
    removeYAxis (arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length;) {
          if (arr[i].yAxisIndex == arr[j].yAxisIndex) {
            arr.splice(j, 1)
          } else {
            j++
          }
        }
      }
      return arr
    },
    //数组对象排序
    sortByKey (array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      })
    },
    // 返回 类似 2016-01-02 格式的字符串
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d
    },
    //获取数组不同项
    getArrDifference (arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    },
    //计算时间差
    getDateDiff (startTime, endTime, diffType) {
      //将xxxx-xx-xx的时间格式，转换为 xxxx/xx/xx的格式 
      startTime = startTime.replace(/\-/g, "/");
      endTime = endTime.replace(/\-/g, "/");
      //将计算间隔类性字符转换为小写
      diffType = diffType.toLowerCase();
      var sTime = new Date(startTime); //开始时间
      var eTime = new Date(endTime); //结束时间
      //作为除数的数字
      var timeType = 1;
      switch (diffType) {
        case "second":
          timeType = 1000;
          break;
        case "minute":
          timeType = 1000 * 60;
          break;
        case "hour":
          timeType = 1000 * 3600;
          break;
        case "day":
          timeType = 1000 * 3600 * 24;
          break;
        default:
          break;
      }
      return parseInt((eTime.getTime() - sTime.getTime()) / parseInt(timeType));
    },
    //显示时间格式
    getNowFormatDate (dates) {
      var date = new Date(dates);
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }

      var hh = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hh + mm + ss;
      return currentdate;
    }
  },


}
</script>