<template>
  <div id="main-content" class="main-content-normal" :class="{'main-content-bigger':$store.state.mainBig}">
    <div class="container-fluid">
      <div class="block-header">
        <div class="row clearfix">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <h1>
              <!-- 设备数据分析 -->
              {{ $t("sidebar.menu.2112313RMTX7WB41") }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row clearfix mt-4">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="row clearfix">
                <div class="col-lg-12 col-lg-auto">
                  <div class="row clearfix">
                    <div class="col-md-4 col-md-auto">
                      <select-ps-id-view :cls="'col-12'" @psIdChange="handlePsIdChange" />
                    </div>
                    <el-col class="col-md-2 col-md-auto text-left" style="max-width:15% !important;">
                      <el-tooltip class="item" :content="$t('charts.chartsxzrb')" placement="bottom" popper-class="dark_aooltip">
                        <el-button :type="primaryArr.days.primaryDay" @click="chageTypeCalendar" circle v-html="$t('history.DatePicker.ChooseDay')"></el-button>
                      </el-tooltip>
                      <el-tooltip class="item " :content="$t('charts.chartsxzyb')" placement="bottom" popper-class="dark_aooltip">
                        <el-button :type="primaryArr.month.primaryMonth" @click="chageTypeCalendar" circle v-html="$t('history.DatePicker.ChooseMonth')"></el-button>
                      </el-tooltip>
                    </el-col>
                    <div class="col-md-12 col-md-auto m-b--5 text-right para_window" v-show="isDevsShow"></div>
                    <!-- :element-loading-text="$t('charts.chartsLoading')" -->
                    <div class="col-xl-6 col-md-auto m-b--5 text-right">
                      <chart-device-type :multiple="true" :cls="'col-12'" :all-show="false" :psId="psId" :calls="callnum" :resets="reset" @deviceTypeIdChecked="handleDeviceTypeChange" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <div class="col-lg-12 col-lg-auto">
                <div class="row clearfix">
                  <div class="col-md-2 col-md-auto">
                    <el-date-picker v-model="valueson1" @change="dateSreach" :picker-options="pickerOptions" :clearable="false" :editable="false" :value-format="calendarArr.format" :type="calendarArr.type" :placeholder="$t('charts.changeTime')" />
                  </div>
                  <div class="col-md-10 col-md-auto m-b--5 text-right">
                    <div class="demo-input-suffix">
                      <div class="col-md-12 para_window" v-show="IsDisabled"></div>
                      <el-checkbox-group size="medium" @change="search" v-model="value1" :placeholder="$t('charts.parameterType')" v-show="showLoading">
                        <!-- <el-checkbox-button v-for="item in options" :key="item.value" :label="$t(item.label)" :value="item.value" :index="item.index" v-show="item.visible">{{$t(item.label)}}</el-checkbox-button> -->
                        <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value" :value="item.value" :index="item.index" v-show="item.visible">{{$t(item.label)}}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="card-header">
              <div class="row clearfix"> 
                <div class="col-lg-7 col-lg-auto">
                  <div class="row clearfix">
                    <div class="col-md-4 col-md-auto">
                      <select-ps-id-view :cls="'col-12'" @psIdChange="handlePsIdChange" />
                    </div>
                    <div class="col-md-8 col-md-auto text-right">
                      <group-device-type :cls="'col-12'" :all-show="false" :psId="psId" :selDeviceTypeId="seldevicetypeid" @deviceTypeIdChecked="handleDeviceTypeChange" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-lg-auto m-b--20 pr-3 text-right">
                  <el-select v-model="value1" multiple :placeholder="$t('charts.parameterType')" class="col-5 text-right" float="right">
                    <el-option v-for="item in options" :key="item.value" :label="$t(item.label)" :value="item.value" :index="item.index">
                    </el-option>
                  </el-select>
                  <el-date-picker v-model="valueson1" :picker-options="pickerOptions" :clearable="false" :editable="false" value-format="yyyy-MM-dd" type="date" :placeholder="$t('charts.changeTime')" float="right" class="col-3">
                  </el-date-picker>
                  <el-tooltip class="item ml-1" :content="$t('charts.search')" placement="bottom" popper-class="dark_aooltip" float="right">
                    <el-button type="primary" icon="fad fa-search" circle @click="search"></el-button>
                  </el-tooltip>
                </div>
              </div>
            </div> -->
            <div class="card-body">
              <div class="col-lg-12" v-if="checkDeviceTypeId == 'loading'">
                <!-- 请选择要对比的设备类型 -->
                {{ $t("charts.deviceType") }}
              </div>
              <device-chars v-show="checkDeviceTypeId != 'loading'" :sreachTypes="sreachType" :calendarTypes="calendarArr.type" :psIds="psId" :checkDeviceTypeIds="checkDeviceTypeIds" :checkDeviceTypeId="checkDeviceTypeId" :searchDate="valueson1" :searchBtn="bntNumber" :chooseDatas="keyDatas" :params="params" :initSons="initSon" :isDevsShows="isDevsShow" :deviceTypePsList="deviceTypeList" @dataLoading="handleDataLoading" @devShowLoading="handleDevShowLoading" />

              <!-- <pcs-chars v-show="checkDeviceTypeId == 1"
                         :chekcDeviceTypeId="checkDeviceTypeId"
                         :searchBtn="bntNumber"
                         :params="params"
                         :searchDate="valueson1"
                         @dataLoading="handleDataLoading" />
              <dc-dc-chars v-show="checkDeviceTypeId == 2"
                           :chekcDeviceTypeId="checkDeviceTypeId"
                           :searchBtn="bntNumber"
                           :params="params"
                           :searchDate="valueson1"
                           @dataLoading="handleDataLoading" />
              <bms-chars v-show="checkDeviceTypeId == 3"
                         :chekcDeviceTypeId="checkDeviceTypeId"
                         :searchBtn="bntNumber"
                         :params="params"
                         :searchDate="valueson1"
                         @dataLoading="handleDataLoading" />
              <ari-chars v-show="checkDeviceTypeId == 4"
                         :chekcDeviceTypeId="checkDeviceTypeId"
                         :searchBtn="bntNumber"
                         :params="params"
                         :searchDate="valueson1"
                         @dataLoading="handleDataLoading" />
              <div class="col-lg-12"
                   v-if="checkDeviceTypeId == 5">
                {{ $t("charts.notSupport") }}
              </div>
              <div class="col-lg-12"
                   v-if="checkDeviceTypeId == 6">
                {{ $t("charts.notSupport") }}
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import SelectPsIdView from "../components/HistoryView/SelectPsId.vue";
import ChartDeviceType from "../components/HistoryView/ChartDeviceType.vue";
import DeviceChars from "../components/ChartView/DeviceChars.vue";


export default {
  components: {
    SelectPsIdView,
    ChartDeviceType,
    DeviceChars
  },
  name: "chart",

  mounted () {
    const chartDom = document.getElementById("EquipmentComparison-Chart");
    const chart = this.$echarts.init(chartDom, 'macarons');
    chart.setoption({
      xAxis: {},
      yAxis: {},
      series: [
        {
          type: "line",
          data: []
        }
      ]
    });
  },


  data () {
    return {
      reset: "",
      loadData: "",
      initSon: "",
      setParamsButton: true,
      callnum: "",
      calendarArr: { "format": "yyyy-MM-dd", "type": "date" },
      primaryArr: {
        "days": {
          "primaryDay": "primary"
        }, "month": {
          "primaryMonth": ""
        }
      },
      sreachType: "",
      deviceTypeArr: [],
      keyDatas: [],
      visibleValue: [],
      olddeviceTypeId: null,
      oldsearchdate: null,
      isDevsShow: false,
      showLoading: true,//多选框显示
      IsDisabled: false,
      oldpsId: null,
      psId: null,
      seldevicetypeid: null,
      checkDeviceTypeId: null,
      checkDeviceTypeIds: [],
      // detailParams:{"param1":{
      //       label: "charts.alike.electricity",
      //       value: "param1",
      //       visible: true,
      //     },"param2":{
      //       label: "charts.alike.voltage",
      //       value: "param2",
      //       visible: true,
      //     },
      //     "param3":{
      //       label: "charts.alike.temp",
      //       value: "param3",
      //       visible: true,
      //     },"param4":{
      //       label: "charts.alike.soc",
      //       value: "param4",
      //       visible: true,
      //     }},

      detailParams: {
        "param1": true,
        "param2": true,
        "param3": true,
        "param4": true
      },
      deviceTypeDetailParams: {
        "1": ["param1", "param2", "param3"],
        "2": ["param1", "param2", "param3"],
        "3": ["param1", "param2", "param3", "param4"],
        "4": ["param3"],
        "5": ["param1"]
      },
      deviceTypeMonthParams: {
        "1": ["param1"],
        "2": ["param1"],
        "3": ["param2", "param3"],
        "4": ["param3"],
        "5": ["param1"]
      },

      // lhl end
      loading: false,
      deviceTypeList: [],
      otimer: null,
      isload: false,
      st: { opacity: 1 },
      options: [],
      optionsPara: [],
      valueson1: "",
      value1: [],
      value2: [],
      sizeForm: {
        region: ""
      },
      params: [],
      bntNumber: 0,
      pickerOptions: {
        disabledDate (time) {
          let buildDate = window.sessionStorage.getItem("buildDate");
          console.log(buildDate);
          let dts = buildDate.split("-");
          let dt = new Date(
            parseInt(dts[0]),
            parseInt(dts[1]) - 1,
            parseInt(dts[2]) - 1
          );
          return time.getTime() >= Date.now() || time.getTime() <= dt.getTime();
        }
      }
    };
  },
  watch: {
    '$store.state.languageId': function () {
      if (this.checkDeviceTypeId.indexOf("_") >= 0) {
        this.checkDeviceTypeId = this.checkDeviceTypeId.split("_")[0] + "_" + Math.floor(Math.random() * 999999);
      }
      // //你需要执行的代码
      // this.timmer=new Date().getTime();//重新加载组件
    }
  },
  mounted () {
    this.callnum = Math.floor(Math.random() * 999999);
    this.showLoading = false;
    this.getParamsButton();
    this.oldsearchdate = this.formatDate(new Date().getFullYear(), (new Date().getMonth() + 1), new Date().getDate());
    this.valueson1 = this.oldsearchdate;
    //通过路由获取传值的deviceId和deviceTypeId;
    if (this.$route.params.deviceTypeId != undefined && this.$route.params.deviceTypeId != "") {
      this.seldevicetypeid = this.$route.params.deviceTypeId;
      //this.handleDeviceTypeChange(this.seldevicetypeid);
    }
    // this.init();
    // this.getPsInfos();
  },
  methods: {
    //切换月和日的日历控件
    chageTypeCalendar () {
      if (this.calendarArr.format == "yyyy-MM-dd") {
        this.calendarArr.format = "yyyy-MM";
        this.calendarArr.type = "month";
        this.primaryArr.days.primaryDay = "";
        this.primaryArr.month.primaryMonth = "primary";
        this.valueson1 = this.formatDate(parseInt(new Date().getFullYear()), parseInt(new Date().getMonth() + 1), 1);
      }
      else {
        this.calendarArr.format = "yyyy-MM-dd";
        this.calendarArr.type = "date";
        this.primaryArr.days.primaryDay = "primary";
        this.primaryArr.month.primaryMonth = "";
        this.valueson1 = this.formatDate(parseInt(new Date().getFullYear()), parseInt(new Date().getMonth() + 1), parseInt(new Date().getDate()))
      }
      this.getParamsButton();
      this.initChange();
      this.reset = Math.floor(Math.random() * 999999);
      this.showLoading = false;
      this.IsDisabled = false;
    },
    handleDevShowLoading (flag) {
      this.isDevsShow = flag;
    },
    handleDataLoading (flag) {
      this.IsDisabled = flag;
    },

    //切换电站、切换日期、切换日月重置
    initChange () {
      this.showLoading = false;
      this.IsDisabled = false;
      this.value1 = [];
      this.params = [];
      this.checkDeviceTypeIds = [];
      this.initSon = Math.floor(Math.random() * 999999);
      this.callnum = Math.floor(Math.random() * 999999);
      // this.initFirstItem();
    },
    initParams () {
      for (let i = 0; i < this.options.length; i++) {
        this.visibleValue[i] = this.options[i]["value"]
      }
    },
    //更换日期时查询数据
    dateSreach () {
      this.showLoading = false;
      if (this.checkDeviceTypeId != "loading") {
        this.initChange();
        this.reset = Math.floor(Math.random() * 999999);
      }
    },
    handlePsIdChange (psBuilddate, psId) {
      //alert(this.$store.state.psId)
      this.showLoading = false;
      window.sessionStorage.setItem("buildDate", psBuilddate);
      this.$store.state.buildDate = psBuilddate;
      this.$emit("parentPsId");
      this.psId = psId;
      this.deviceTypeArr = [];
      this.paramSetChoose();
      if (this.oldpsId != null) {
        this.initChange();
      }
      if (this.oldpsId == null) {
        this.oldpsId = psId;
      }
      // this.initFirstItem();
    },
    handleDeviceTypeChange (deviceTypeId, deviceTypeIds,deviceTypeList) {
      if( this.deviceTypeList.length == 0){
        deviceTypeList = this.sortByKey(deviceTypeList,'deviceTypeId')
        let deviceTypes = []
        for(let i=0;i < deviceTypeList.length;i++){
           deviceTypes[i] = deviceTypeList[i].deviceTypeId
        }
        this.deviceTypeList = deviceTypes;
        //console.info(33333333,this.deviceTypeList)
      }
      this.loadData = Math.floor(Math.random() * 999999);
      if (deviceTypeIds.length > 0) {
        this.showLoading = true;
      }

      this.initFirstItem(deviceTypeIds);

      this.checkDeviceTypeId = deviceTypeId + "_" + Math.floor(Math.random() * 999999); //this.checkDeviceTypeId 做为参数使用请去除"_"和后面的随机数
      this.checkDeviceTypeIds = deviceTypeIds;
      console.info("DeviceChart this.checkDeviceTypeIds:", this.checkDeviceTypeIds);

      this.paramSetChoose();

      if (!this.setParamsButton) {
        this.checkDeviceTypeId = [];
        this.setParamsButton = true;
      }
      if (this.callnum != "") {
        this.callnum = "";
      }

      //判断选中的设备类型是否是0个如果是，则将所有功率、电压、温度、SOC选中值清空
      if (this.checkDeviceTypeIds != null && this.checkDeviceTypeIds.length == 0) {
        this.value1 = [];
        this.params = [];
      }
      else {
        //移除已选中设备中不包含的参数
        for (var i = 0; i < this.options.length; i++) {
          for (var j = 0; j < this.value1.length; j++) {
            if (this.options[i]["value"] == this.value1[j] && this.options[i]["visible"] == false) {
              var index = this.value1.indexOf(this.value1[j]);
              if (index > -1) {
                this.value1.splice(index, 1);
              }
            }
          }
        }

      }

      if (this.checkDeviceTypeId != "loading") {
        this.oldsearchdate = this.valueson1;

      }

      if (this.olddeviceTypeId == this.checkDeviceTypeId && this.oldsearchdate == this.valueson1) {
        return false;
      } else {
        this.olddeviceTypeId = this.checkDeviceTypeId;
        this.oldsearchdate = this.valueson1;
      }

    },
    //选中第一项
    initFirstItem (deviceTypeIds) {
      //选中设备时默认选中第一项,并且没有选中参数

      if (deviceTypeIds.length == 1 && this.params.length == 0) {
        this.IsDisabled = false
        this.showLoading = true;
        switch (deviceTypeIds[0]) {
          case 4://空调选中
            this.value1 = ["param3"]
            this.params = ["param3"]
            break;
          default:
            //月分析选中蓄电池
            if (deviceTypeIds[0] == 3 && this.calendarArr.type == "month") {
              this.value1 = ["param2"]
              this.params = ["param2"]
            } else {//默认选中param1
              this.value1 = ["param1"]
              this.params = ["param1"]
            }
            break;
        }

      }
    },
    //根据选择设备找到查询的数据字段
    paramSetChoose () {
      let union = [];
      let unionArr = [];
      if (typeof (this.checkDeviceTypeIds) != "undefined") {
        switch (this.calendarArr.type) {
          case "date":
            //this.options=[];


            for (var i = 0; i < this.options.length; i++) {
              this.options[i]["visible"] = false;
            }


            let deviceTypeId = this.checkDeviceTypeIds[0]
            union = this.deviceTypeDetailParams[deviceTypeId];

            if (this.checkDeviceTypeIds.length > 1) {
              for (var i = 1; i < this.checkDeviceTypeIds.length; i++) {
                console.log(this.checkDeviceTypeIds[i]);
                let item = this.deviceTypeDetailParams[this.checkDeviceTypeIds[i]];
                union = new Set([...union, ...item])
              }
            }
            if (typeof (union) != "undefined") {
              unionArr = Array.from(union)

              for (var i = 0; i < unionArr.length; i++) {
                for (var j = 0; j < this.options.length; j++) {
                  if (this.options[j]["value"] == unionArr[i]) {
                    this.options[j]["visible"] = true;
                  }
                }
              }
            }
            break;
          case "month":
            for (var i = 0; i < this.options.length; i++) {
              this.options[i]["visible"] = false;
            }
            union = this.deviceTypeMonthParams[this.checkDeviceTypeIds[0]];

            if (this.checkDeviceTypeIds.length > 1) {
              for (var i = 1; i < this.checkDeviceTypeIds.length; i++) {
                console.log(this.checkDeviceTypeIds[i]);
                let item = this.deviceTypeMonthParams[this.checkDeviceTypeIds[i]];
                union = new Set([...union, ...item])
              }
            }
            if (typeof (union) != "undefined") {
              unionArr = Array.from(union)
              for (var i = 0; i < unionArr.length; i++) {

                for (var j = 0; j < this.options.length; j++) {
                  if (this.options[j]["value"] == unionArr[i]) {
                    this.options[j]["visible"] = true;
                  }
                }
              }
            }
            break;

        }
      }
      console.log("this.options", JSON.stringify(this.options));
    },
    paramSetView () {
      for (let i = 0; i < this.options.length; i++) {
        for (let j = 0; j < this.visibleValue.length; j++) {
          if (this.options[i]["value"] == this.visibleValue[j]) {
            this.options[i]["visible"] = true;
          }
        }
      }
    },
    search () {
      // this.showLoading = false;
      if (this.value1.length > 2) {
        let name = ""
        for (let i = 0; i < this.options.length; i++) {
          if (this.value1[2] == this.options[i]["value"]) {
            name = this.options[i]["label"];
          }
        }
        //无法选中,参数最多只能选择2种
        swal({
          title: this.$t(name) + "" + this.$t("charts.chooseSet"),
          text: "",
          type: "warning",
          confirmButtonText: this.$t("charts.msg.confirm")
        });
        this.value1.splice(2, 1);
        return false;
      }

      this.IsDisabled = true;
      this.params = this.value1;
      this.bntNumber = Math.floor(Math.random() * 999999);
    },
    getParamsButton () {
      this.options = [];
      switch (this.calendarArr.type) {
        case "month":
          this.options.push({
            label: "charts.alike.electricity",
            value: "param1",
            visible: true,
          });
          this.options.push({
            label: "charts.alike.voltage",
            value: "param2",
            visible: true,
          });
          this.options.push({
            label: "charts.alike.temp",
            value: "param3",
            visible: true,
          });
          break;
        case "date":
          this.options.push({
            label: "charts.alike.power",
            value: "param1",
            visible: true,
          });
          this.options.push({
            label: "charts.alike.voltage",
            value: "param2",
            visible: true,
          });
          this.options.push({
            label: "charts.alike.temp",
            value: "param3",
            visible: true,
          });
          this.options.push({
            label: "charts.alike.soc",
            value: "param4",
            visible: true,
          });
          break;
      }

      this.initParams();
    },
    //数组对象排序
    sortByKey (array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      })
    },
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d
    },
    unique (array) {
      let n = {}, r = [], len = array.length, val, type;
      for (let i = 0; i < array.length; i++) {
        val = array[i];
        type = typeof val;
        if (!n[val]) {
          n[val] = [type];
          r.push(val);
        } else if (n[val].indexOf(type) < 0) {
          n[val].push(type);
          r.push(val);
        }
      }
      return r;
    },
  }
};
</script>

<style>
.para_window {
  display: block;
  margin: auto;
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
